// src/Components/LoadingScreen.jsx
import React from 'react';
import './LoadingScreen.css';
import Guitar from '../Guitar';

const LoadingScreen = () => {
  return (
    <div className='loading-container'>

      <Guitar className="guitar-box"/>
    
    </div>
  );
};

export default LoadingScreen;
